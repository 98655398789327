.ExternalRedirect {
    margin-bottom: 25em;
}

.ExternalRedirect p {
    margin: 0;
}

.TextContainer,
.IconContainer {
    display: inline-block;
    vertical-align: top;
}

.TextContainer {
    width: 40%;
}

.RedirectText {
    font-size: 1.618em;
}