.PFSFooterBranding {
    margin: 0;
    padding: 0em 1em 2em;
    padding-bottom: 1em;

    position: relative;
}

.BrandingSection {
    display: inline-block;
    width: 33.33%;
    font-size: 0.8em;
}

.BrandingSection a {
    text-decoration: none;
    color: var(--color-pfs-gray);
}

.BrandingSection:nth-child(1) {
    text-align: left;
}

.BrandingSection:nth-child(2) {
    text-align: center;
}

.BrandingSection:nth-child(3) {
    text-align: right;
}

@media screen and (max-width: 40em) {
    .BrandingSection {
        display: block;
        margin: 0;
        width: 100%;
    }

    .BrandingSection:nth-child(n) {
        text-align: center;
    }

    .BrandingSection:nth-child(2) {
        position: absolute;
        bottom: 5em;
        text-align: center;
        width: 1em;
        left: 50%;
        transform: translateX(-50%);
    }
}