.NavMenuItems {
}
.dropdown:after {
  position: absolute;
  content: '';
  top: 0;
  left: 9.6em;
  width: 0;
  height: 0;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
  border-bottom: 0.8em solid var(--color-pfs-blue);
}
.dropdownMenu {
  padding: 1em;
  width: 20em;
  background-color: var(--color-pfs-blue);
  border-radius: 0.3em;
  margin-top: 0.5em;
}

.dropdownMenu > li {
  color: white;
}
.dropdownMenu > li:hover {
  background-color: var(--color-pfs-blue);
}
.mobileMenu {
  text-align: end;
}
.menuLink {
  cursor: pointer;
}

@media screen and (min-width: 64em) {
  .mobileLink {
    display: none;
  }
}
@media screen and (max-width: 64em) {
  .dropdownMenu {
    display: none;
  }
  .menuLink {
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;
  }
  .dropdown:after {
    border-left: 0em;
    border-right: 0em;
    border-bottom: 0em;
  }
}
