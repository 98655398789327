.ArticleLayout {
  display: flex;
  justify-content: space-around;
}
.content {
  margin: 0.5em;
  padding-top: 3em;
  width: 65%;
  font-weight: 300;
  text-align: start;
}
.content p,
.content ul,
.content ol,
.content strong {
  font-size: 1.2em;
  margin-bottom: 1em;
}
.content h2 {
  font-weight: 700;
}
.content strong {
  font-weight: 600;
}
@media screen and (max-width: 60em) {
  .content {
    width: 50%;
  }
}
@media screen and (max-width: 46em) {
  .ArticleLayout {
    display: block;
  }
  .content {
    width: 100%;
  }
}
