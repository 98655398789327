.SideArticleCard {
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  padding-bottom: 2.5em;
}
.ArticleImage {
  height: 6.5em;
  width: 7em;
  border-radius: 5%;
  object-fit: cover;
  margin-right: 1em;
}
.ArticleTitle {
  font-weight: bold;
  max-height: 3.5em;
  overflow: hidden;
}
.ArticleSummary {
  color: var(--color-pfs-text-gray);
}
.ArticleInfo {
  display: flex;
  color: var(--color-pfs-light-gray);
}
.icon {
  margin-right: 0.75em;
}
