.PFSHeader {
    --header-padding: 2em 1em;
}

#HeaderPadding {
    padding: var(--header-padding);
    position: relative;
}

#RightSideContainer {
    display: inline-block;
    position: absolute;
    right: 0em;
    padding: 0em var(--header-padding);
}
.icon {
    display: none;
}
.DesktopNavList {
    list-style: none;
    display: inline-block;
    padding-right: 2em;
}

.DesktopNavListItem {
    display: inline-block;
    margin-left: 2em;
}

.DesktopNavListItem > a {
    text-decoration: none;
    color: black;
}

.DesktopNavListItem > .NavActive {
    color: var(--color-pfs-blue);
}

/* Desktop Nav as it gets smaller */
@media screen and (max-width: 75em) {
    .DesktopNavList {
        padding-right: 1em;
    }
}

@media screen and (max-width: 64em) {
    .DesktopNavList {
        display: none;
    }
}

@media screen and (max-width: 40em) {
    #RightSideContainer {
        padding-right: 3em;
    }
}

@media screen and (max-width: 25em) {
    .icon {
        display: inline-block;
    }
    .logo {
        display: none;
    }
}