.PfsPartnersIndex p,
.PfsPartnersIndex h1 {
  padding: 0em 1em;
}
.PfsEmail {
  color: var(--color-pfs-blue);
}

.ServiceCardSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.ServiceCardContainer {
  display: inline-block;
  width: 33%;
  margin-bottom: 2em;
}

@media screen and (max-width: 48em) {
  .ServiceCardContainer {
    width: 50%;
  }
}

@media screen and (max-width: 35.5em) {
  .ServiceCardContainer {
    width: 100%;
  }
}
