.PfsAcquisitionsIndex {}

.PfsAcquisitionsIndex p,
.PfsAcquisitionsIndex h1 {
    padding: 0em 1em;
}

.PremierLogo {
    max-width: initial !important;
    max-height: initial !important;
    width: 10em;
    height: auto;
}

.ServiceCardSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.ServiceCardContainer {
    display: inline-block;
    width: 33%;
    margin-bottom: 2em;
}

@media screen and (max-width: 48em) {
    .ServiceCardContainer {
        width: 50%;
    }
}

@media screen and (max-width: 35.5em) {
    .ServiceCardContainer {
        width: 100%;
    }
}