.PFSWhatWeDoTaxes {
}
.TitleText {
  text-align: center;
  font-size: 3.875em;
  font-weight: 900;
  margin: 0;
}
#SubtitleText {
  font-size: 1.5em;
  font-weight: 250;
  margin-bottom: 2.5em;
}

#ServiceCardsContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 5em;
}

.ServiceCardContainer {
  display: inline-block;
  width: 25%;
  margin-bottom: 2.5em;
}

@media screen and (max-width: 55em) {
  .ServiceCardContainer {
    width: 50%;
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 35.5em) {
  .ServiceCardContainer {
    width: 95%;
    margin-bottom: 1em;
  }
}
