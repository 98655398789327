.PfsTabs {
}
.tab {
  font-size: 1.1em;
  display: inline-block;
  color: var(--color-pfs-blue);
  border: 0.01em solid var(--color-pfs-blue);
  border-radius: 1em;
  padding: 0.3em;
  width: 12em;
  margin: 1em;
  text-align: center;
  cursor: pointer;
}
.selected {
  color: white;
  background-color: var(--color-pfs-blue);
}
