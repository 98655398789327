.PfsBulletPoints {
  padding: 2em;
}
.BulletTitle {
  color: var(--color-pfs-blue);
  font-weight: 400;
  font-size: 1.5em;
  margin-top: 2em;
}
.Bullet {
  display: flex;
  font-size: 1.275em;
  font-weight: 300;
  margin-bottom: 1em;
  align-items: center;
}
.BulletIcon {
  background-color: var(--color-pfs-blue);
  height: 0.8em;
  padding: 0.2em;
  color: white;
  width: 0.8em;
  margin-right: 0.618em;
  border-radius: 50%;
  text-align: center;
}
.endLine {
  border-bottom: 0.01em solid #e2e2e2;
}
