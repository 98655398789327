.PFSServicePoints {
    display: inline-block;
    width: 95%;
    vertical-align: top;
    padding-bottom: 2em;
}

.PFSServicePoints li {
    font-size: 1.625em;
    color: var(--color-pfs-text-gray);
}
.unOrderedList li {
    font-size: 0.9em;
}
.PointsHeader {
    color: var(--color-pfs-blue);
    font-weight: 600;
    font-size: 1.75em;
}

@media screen and (max-width: 75em) {
    .PFSServicePoints {
        padding: 0em 1em;
    }
}