.PfsArticles {
  padding: 0 2em;
  margin-bottom: 20em;
}
.searchInput {
  padding: 0.5em 1em;
  margin-bottom: 4em;
  height: 3em;
  width: 85%;
  border: none;
  border-radius: 1.5em;
  background: #f7f7f7;
}
.searchInput::placeholder{
  color: #a3a3a3;
}
.searchIcon{
  position: relative;
  right: 2.25em;
  bottom: 0.1em;
}