.AuthorPanel {
  border: 0.1em solid #e3e3e3;
  padding: 1.5em 4em 0em 3em;
  background-color: #fff;
  border-radius: 1em;
  margin: 4em 0.5em;
}
.Header {
  font-size: 1.75em;
  font-weight: bold;
  color: var(--color-pfs-gray);
}
.AuthorName {
  font-size: 1.65em;
  color: var(--color-pfs-gray);
}
