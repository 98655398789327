.PfsVerticalTableComponent {
  margin-bottom: 4em;
  padding: 0.5em;
}
.TableTitle {
  color: var(--color-pfs-blue);
  font-weight: 400;
  font-size: 1.5em;
  margin-left: 1em;
}
.TableRow {
  display: flex;
  margin-bottom: 1em;
  font-weight: 400;
}
.TableCell {
  min-height: 4.5em;
  display: flex;
  align-items: center;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 1em;
  font-size: 1.3em;
  width: 65%;
  background-color: var(--color-pfs-table-body-cell);
  color: var(--color-pfs-blue);
}
.TableCell:first-child {
  width: 35%;
  background-color: var(--color-pfs-blue);
  color: var(--color-pfs-light-blue);
  margin-right: 0.5em;
}

@media screen and (max-width: 55em) {
  .TableRow {
    display: block;
  }
  .TableCell {
    width: 100%;
    margin-bottom: 0.5em;
    margin-bottom: 2em;
    min-height: 10em;
  }
  .TableCell:first-child {
    width: 100%;
    max-height: 4.5em;
    min-height: 4.5em;
  }
  .PfsVerticalTableComponent {
    padding: 2em;
  }
}
