.PFSContactForm {
    position: relative;
}

#BackgroundLogoContainer {
    position: absolute;   
    height: 100%;
    width: 100%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 0;
}

#BackgroundLogoContainer svg {
    height: 100%;
    width: auto;
    transform: translateX(-20%);

    fill: #000000;
    opacity: 10%;
}

#FormContainer {
    position: relative;
    z-index: 1;
}

#FormContainer input,
#FormContainer textarea {
    display: block;
    font-size: 1.618em;
    width: 100%;
    border-radius: 1em;
    padding: 0.618em;
    border: 1px solid var(--color-pfs-light-gray);
    color: var(--color-pfs-gray);
    margin-bottom: 1em;
    box-sizing: border-box;
}

#FormContainer textarea {
    font-size: 1.5em;
    height: 10em;
}

#FormContainer .NameInputs {
    width: 50%;
    display: inline-block;
}

#FormContainer .NameInputs:first-child input {
    width: 95%;
}

#FormContainer input[type='submit'] {
    background-color: var(--color-pfs-blue);
    color: white;
    cursor: pointer;
}

#FormContainer input[type='submit']:hover {
    background-color: var(--color-pfs-blue-hover);
}

#FormContainer input:disabled,
#FormContainer textarea:disabled {
    cursor: not-allowed;
}

.ErrorNotification,
.SuccessNotification {
    padding: 1em;
    margin:0.25em 0em;
    color: white;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.25em;
}

.SuccessNotification {
    background-color: green;
}

.ErrorNotification {
    background-color: red;
}

.checkboxContainer {
    display: flex;
    gap: 1em;
}
.checkboxContainer label {
    font-size: 1.5em;
    font-weight: 400;
}
.checkbox {
  appearance: none;
  width: 1.15em !important;
  height: 1.15em !important;
  border-radius: 0.5em !important;
  outline: none;
  background-color: #fff;
  position: relative;
}
.checkbox:checked::before {
  content: '\2714';
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
  color: white;
}
.checkbox:checked {
  background-color: var(--color-pfs-blue)
}

@media screen and (max-width: 48em) {
    #FormContainer .NameInputs {
        width: 100%;
        display: block;
    }

    #FormContainer .NameInputs:first-child input {
        width: 100%;
    }

    #BackgroundLogoContainer {
        overflow: hidden;
    }
}