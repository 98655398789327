.PFSContactCta {
  border-radius: 1em;
  padding: 5em;
  color: white;
  background-color: var(--color-pfs-blue);
  position: relative;
  z-index: 0;
  display: block;
  margin: 1.618em auto;
}

.LogoLinesContainer {
  position: absolute;
  top: 0;
  right: 10%;
  height: 100%;
  z-index: 1;
  width: 100%;
}

.LogoLinesContainer svg {
  height: 100%;
  width: auto;
  display: block;
  margin: 0 0 0 auto;
}

.CTAText,
.CTAButtons {
  position: relative;
  z-index: 2;
}

.CTAText p {
  margin: 0;
  font-size: 2em;
}

.CTABold {
  font-weight: 900;
  font-size: 2.5em !important;
}

.CTAButtons {
  margin-top: 1.618em;
}

.CTAButtons a {
  display: inline-block;
  text-decoration: none;
  padding: 0.618em;
  border-radius: 1em;
  color: white;
  margin-right: 1em;
  border: 0;
  font-size: 1.625em;
}

.CTAButtons a:first-child {
  background-color: var(--color-pfs-blue);
  border: 1px solid white;
}

.CTAButtons a:nth-child(2) {
  background-color: var(--color-pfs-blue-hover);
}

@media screen and (max-width: 75em) {
  .PFSContactCta {
    width: 80%;
  }

  .LogoLinesContainer {
    bottom: 0;
    top: auto;
    height: 50%;
    right: 0%;
  }
}

@media screen and (max-width: 64em) {
  .PFSContactCta {
    padding: 2em;
  }

  .CTAText p {
    text-align: center;
  }

  .CTAButtons {
    text-align: center;
  }

  .CTAButtons a {
    margin: 0.5em 1em;
  }
}
