.PfsYorke {
  text-align: center;
}
.PfsYorke h1,
.PfsYorke div {
  padding: 0em 1em;
}

.content {
  margin: 2em 0em 10em 0em;
}
.content p,
.content div {
  text-align: start;
  font-size: 1.35em;
}
#homeBtn {
  display: inline-block;
  color: white;
  font-size: 1.1em;
  background-color: var(--color-pfs-blue);
  border-radius: 1em;
  padding: 1em 2em;
  margin: 1em 0em;
  transform: translateY(-0.5em);
  cursor: pointer;
  text-decoration: none;
}
#homeBtn:hover {
  background-color: var(--color-pfs-blue-hover);
}
.logo {
  height: 7em;
  padding: 1em;
}
