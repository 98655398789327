.TitleText,
.SubtitleText {
    color: var(--color-pfs-gray);
}

.TitleText {
    font-weight: 900;
    font-size: 3.875em;
}

.SubtitleText {
    font-size: 3.25em;
}

.Fade {
    opacity: 0.5;
}

.IntroContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5em;
}

.IntroImage {
    width: 32em;
    border-radius: 1em;
    margin-right: 3.2em;
    object-fit: cover;
}

.IntroText {
    flex: 1;
}

.IntroText p {
    font-size: 1.618em;
}

@media screen and (max-width: 72em) {
    .IntroContainer {
      flex-direction: column;
    }

    .IntroImage {
        width: 80%;
        margin: 0;
        margin-bottom: 2.5em;
    }

    .IntroText {
        padding: 0em 2.5em;
    }
}