.PFSHomeBanner {
    padding: 0em 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.CTAText {
    text-align: left;
    width: 50%;
    vertical-align: middle;
    display: table-cell;
    padding: 8em 0em;
}

#HeaderText {
    font-weight: 400;
    font-size: 3.25em;
    color: var(--color-pfs-gray);
}

#BoldHeader {
    font-weight: 900;
}

#BannerText,
#LearnMoreBtn {
    font-size: 1.5em;
}

#LearnMoreBtn {
    display: inline-block;
    padding: 0.618em 1em;
    border-radius: 1em;
    color: white;
    background-color: var(--color-pfs-blue);
    cursor: pointer;
    border: 0;
    text-decoration: none;
}

.BannerGraphic {
    width: 50%;
}

#BannerFadedLogo,
#BannerStockProfessional {
    width: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#BannerFadedLogo {
    height:100%;
    opacity: 0.2;
}

#BannerStockProfessional {
    position: absolute;
}

.BannerItem {
    display: none;
    max-width: 40em;
}

.BannerItem img {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 75em) {
    .CTAText {
        padding-left: 1.618em;
    }
    
    .BannerGraphic img {
        padding: 0 1.618em;
    }
}

@media screen and (max-width: 64em) {
    .CTAText {
        padding: 0em 0em 0em 1.618em;
    }
}

@media screen and (max-width: 48em) {
    .PFSHomeBanner {
        flex-direction: column;
    }

    .CTAText,
    .BannerGraphic {
        width: 95%;
        display: block;
        margin: 2em auto;
    }

    .CTAText {
        padding: 0;
    }
}