.SharePanel {
  border: 0.1em solid #e3e3e3;
  padding: 1.5em 4em 1.5em 3em;
  background-color: #fff;
  border-radius: 1em;
  margin: 4em 0.5em;
}
.Icon {
  background-color: var(--color-pfs-blue);
  height: 0.7em;
  padding: 0.3em;
  color: white;
  width: 0.7em;
  margin-right: 0.4em;
  border-radius: 50%;
}
.Header {
  font-size: 1.75em;
  font-weight: bold;
  color: var(--color-pfs-gray);
}
