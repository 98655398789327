.MobileNavButton {
    height: 1.618em;
    width: 1.618em;
    display: inline-block;
    z-index: 100;
    right: 1.618em;
}

/* Hamburger Patties */
.MobileNavButton > div {
    height: 0.25em;
    width: 1.618em;
    background-color: var(--color-pfs-blue);
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: initial;

    transition: opacity 250ms ease-out, transform 250ms ease-out;
}

.MobileNavButton > div:nth-child(1) {
    transform: translateY(-0.5em);
}
.MobileNavButton > div:nth-child(3) {
    transform: translateY(0.5em);
}

.IsOpen > div:nth-child(1) {
    transform: rotate(-45deg) translateY(0em);
}
.IsOpen > div:nth-child(2) {
    opacity: 0;
}
.IsOpen > div:nth-child(3) {
    transform: rotate(45deg) translateY(0em);
}

@media screen and (min-width: 64em) {
    /* Hide the mobile nav on tablet+ */
    .MobileNavButton {
        display: none;
    }
}