.PfsArticleCard {
  margin-top: 3em;
  display: flex;
  align-items: center;
  padding-bottom: 2em;
  border-bottom: 0.1em solid var(--color-pfs-light-gray);
}
.PfsArticleCard p {
  margin-bottom: 0.5em;
  font-size: 1.25em;
}
.ArticleImage {
  height: 10em;
  width: 15em;
  border-radius: 5%;
  object-fit: cover;
  margin-right: 2.5em;
}
.ArticleTitle {
  font-weight: bold;
}
.ArticleSummary {
  color: var(--color-pfs-text-gray);
}
.ArticleInfo {
  display: flex;
  color: var(--color-pfs-light-gray);
}
.icon {
  margin-right: 0.75em;
}
@media screen and (max-width: 35em) {
  .PfsArticleCard {
    display: block;
  }
  .ArticleImage {
    height: 18em;
    width: 100%;
    object-fit: cover;
    margin-right: 0em;
    margin-bottom: 1.5em;
  }
}
