.PFSWhatWeDoIndex {}

#PageHeader,
#PageSubtitle {
    padding: 0em 0.25em;
    text-align: center;
    margin: 0;
}

#ServiceCardsContainer {
    margin-bottom: 10em;
    margin-top: 5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ServiceCardContainer {
    display: inline-block;
    width: 33%;
    margin-bottom: 2em;
}

@media screen and (max-width: 48em) {
    .ServiceCardContainer {
        width: 50%;
    }
}

@media screen and (max-width: 35.5em) {
    .ServiceCardContainer {
        width: 100%;
    }
}