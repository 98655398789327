.HeaderCallUs,
.HeaderCallUs div {
    display: inline-block;
}

#TextContainer {
    text-align: right;
}

#CallUsText {
    color: var(--color-pfs-blue);
    font-weight: 400;
    font-size: 0.85em;
}

#CallUsPhoneNumber {
    color: var(--color-pfs-blue);
    font-weight: 600;
    font-size: 0.95em;
}

#IconContainer {
    color: var(--color-pfs-blue);
    margin-left: 0.618em;
}

#ContactBtn {
    display: inline-block;
    color: white;
    background-color: var(--color-pfs-blue);
    border-radius: 1em;
    padding: 0.5em;
    margin: 0em 0.618em;
    transform: translateY(-0.5em);
    cursor: pointer;
    text-decoration: none;
}

#ContactBtn:hover {
    background-color: var(--color-pfs-blue-hover);
}

@media screen and (max-width: 33em) {
    #ContactBtn {
        display: none;
    }
}