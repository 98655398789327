.Banner {
  background-color: var(--color-pfs-blue);
  border: none;
  text-align: center;
  align-items: center;
  height: 3.5em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.BannerWrapper {
  margin-bottom: 3em;
}
.Banner div {
  font-size: 1.05em;
  color: white;
}
/* The close button on the banner */
.Banner > button > span > svg {
  color: white;
  width: 1.12em;
  height: 1.12em;
}
.subscribeButton {
  color: var(--color-pfs-blue);
  border-radius: 1em;
  margin-left: 1em;
  width: 8.5em;
  border-color: rgba(0, 0, 0, 0);
}
@media screen and (max-width: 40em) {
  .Banner {
    height: 5em;
  }
}
