.PFSFooter {
  padding: 0;
  background-color: var(--color-pfs-light-blue);
}

#FooterWaveContainer {
  position: relative;
}

#FooterWave {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: auto;
}
