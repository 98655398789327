.PfsWhoWeAreCollage {
    text-align: center;
}

.StaffImages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 2.5em;
    width: 95%;
}

.StaffImages img {
    width: 5em;
    height: 5em;
    object-fit: cover;
    margin: 0.25em;
    box-shadow: 1px 1px 3px rgb(192, 192, 192);
    border-radius: 0.5em;
    padding: 0.25em;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.StaffImages img:hover {
    transform: scale(1.2);
    box-shadow: none
}