.MobileNavMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    z-index: 99;

    transition: opacity 0.25s ease;
}

#MobileNavContainer {
    position: fixed;
    height: 100%;
    width: 12em;
    margin: 0;
    right: 0;
    top: 0;
    background-color: white;

    padding-top: 6.18em;
    transition: right 0.25s ease;
}

#LogoContainer {
    text-align: right;
    padding-right: 1em;
}

.MobileNavMenuList {
    list-style: none;
    padding-left: 0;
}

.MobileNavItem {
    padding: 1.25em 0em;
    border-bottom: 1px solid #d4d4d4;
}

.MobileNavItem:first-child {
    border-top: 1px solid #d4d4d4;
}

.MobileNavItem > a {
    color: black;
    text-decoration: none;
    text-align: right;
    display: block;
    margin-right: 1em;
}

.IsHidden {
    opacity: 0;
    pointer-events: none;
}

.IsHidden #MobileNavContainer {
    right: -12em;
}

@media screen and (min-width: 64em) {
    .MobileNavMenu {
        display: none;
    }
}