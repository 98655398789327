.PFS404Page {}

#TextContainer,
#IconContainer {
    display: inline-block;
}

#TextContainer {
    font-size: 2.5em;
    width: 40%;
}

#IconContainer {
    width: 60%;
    font-size: 20em;
    padding-left: 0.25em;
}

#IconContainer svg {
    color: var(--color-pfs-blue-hover);
}

@media screen and (max-width: 75em) {
    #TextContainer {
        padding-left: 1em;
    }
}

@media screen and (max-width: 55em) {
    #IconContainer {
        font-size: 15em;
        vertical-align: top;
    }
}

@media screen and (max-width: 40em) {
    #TextContainer {
        width: 100%;
    }

    #IconContainer {
        font-size: 10em;
        width: 100%;
    }
}