.PfsHorizontalTableComponent {
  margin-top: 3em;
  margin-bottom: 8em;
  padding: 0.5em;
}
.Table {
  width: 100%;
  min-width: 80%;
  overflow-x: auto;
  border-spacing: 0.5em;
  border-collapse: separate;
}
.Tabletitle {
  color: var(--color-pfs-blue);
  font-weight: 400;
  font-size: 1.5em;
  margin-left: 1em;
}
.TableRow {
  margin-bottom: 1em;
  font-weight: 400;
}
.TableCell {
  margin: 1em;
  height: 3em;
  align-items: center;
  padding: 0 1em 0 1em;
  border-radius: 1em;
  font-size: 1.3em;
}
.TableHeader {
  background-color: var(--color-pfs-blue);
  color: var(--color-pfs-light-blue);
}
.TableBody {
  background-color: var(--color-pfs-table-body-cell);
  color: var(--color-pfs-blue);
}
