.PFSServiceCard {
    position: relative;
    display: block;
    margin: 0 auto;
    height: 15em;
    width: 15em;
    border-radius: 1em;
    background-color: white;

    text-align: center;
    text-decoration: none;
    color: black;
    box-shadow: 0 0 1em rgba(0,0,0,0.25);

    transition: background-color 250ms ease;
}

.PFSServiceCard:hover {
    background-color: var(--color-pfs-blue);
}

.PFSServiceCard:hover .IconContainer,
.PFSServiceCard:hover svg,
.PFSServiceCard:hover .TitleContainer,
.PFSServiceCard:hover .SubtitleContainer {
    color: white;
    fill: white;
}

.CardContentContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.IconContainer,
.PFSServiceCard svg,
.TitleContainer,
.SubtitleContainer {
    transition: color 250ms ease;
}

.PFSServiceCard svg {
    fill: var(--color-pfs-blue);
    max-width: 4.5em;
    max-height: 4.5em;
}

.IconContainer {
    display: block;
    color: var(--color-pfs-blue);
}

.TitleContainer {
    font-size: 1.33em;
    font-weight: 600;
    min-width: 11em
}

.TitleContainer p {
    margin-bottom: 0;
}

.SubtitleContainer {
    font-size: 1.33em;
    margin-top: 1em;
}