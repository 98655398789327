.PfsCareersCoreValues {}

.PfsCareersCoreValues h2 {
    font-size: 2em;
    margin-top: 2em;
}

.CarouselItem {
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 50px;

    background-color: rgba(170, 170, 170, 0.1);
    border-radius: 1em;

    /* new styles for hover effect */
    transition: transform 0.2s ease-in-out;
}

.CarouselItem:hover {
    transform: scale(1.05);
}

.ValueIcon {
    color: var(--color-pfs-blue);
}

.ValueText {
    margin-top: 20px;
}

.ValueText h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: rgba(97, 97, 97, 0.85)
}

.ValueText p {
    font-size: 1.2rem;
    line-height: 1.5;
}