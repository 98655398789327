.PFSDiamondBarMap {
    background-color: var(--color-pfs-blue);
    color: white;
    padding: 1em;
    border-radius: 1em;
    text-align: center;
}

#TitleContainer {
    margin-top: 2em;
}

#TitleContainer p {
    margin: 0;
}

#OfficeTitle {
    font-size: 3em;
    font-weight: 900;
}

#StreetAddress {
    font-size: 2em;
    color: rgba(255,255,255, 0.5);
}

#MapContainer {
    border-radius: 1em;
}

#MapContainer iframe {
    width: 100%;
    height: 30em;
    border-radius: 1em;
    margin-top: 2em;
}

@media screen and (max-width: 75em) {
    .PFSDiamondBarMap {
        width: 85%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 48em) {
    #MapContainer iframe {
        height: 20em;
    }
}