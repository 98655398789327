.PFSServiceHeader {
    text-align: center;
    padding: 0em 1em;
    margin-bottom: 5em;
}

.PFSServiceHeader p {
    margin: 0;
}

.period {
    font-size: 2em;
    color: var(--color-pfs-gray);
}