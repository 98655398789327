.PfsRecordRetentions {
}
.heading {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 1em;
}
.paragraph {
  font-size: 1.275em;
  font-weight: 300;
  margin-bottom: 1em;
}
