/* Define app wide variables */
:root {
  --color-pfs-blue: #176EA4;
  --color-pfs-light-blue: #f0f5f9;
  --color-pfs-blue-hover: #143A69;
  --color-pfs-gray: #4b4b4f;
  --color-pfs-text-gray: #5d5c61;
  --color-pfs-light-gray: #cbcbcb;
  --color-pfs-image-border-blue: #a9cce0;
  --color-pfs-table-body-cell: #e8f2f7;
}

body {
  margin: 0;
}

* {
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
}
