.PfsArticleCta {
  display: flex;
  margin: 10em 1em;
}
.browseButton {
  display: block;
  text-align: center;
  color: white;
  background-color: var(--color-pfs-blue);
  border-radius: 1em;
  padding: 0.3em;
  width: 12em;
  position: relative;
  left: 65%;
  margin-bottom: 3.2em;
}
.browseButton:hover {
  color: white;
  background-color: var(--color-pfs-blue-hover);
}
.heading {
  margin-bottom: 1em;
  margin-left: 2em;
  font-size: 2em;
  color: var(--color-pfs-text-gray);
}
.sideCards {
  display: grid;
  padding: 0em 1em;
  grid-template-columns: auto auto;
}
.Cards {
  display: block;
}
@media screen and (max-width: 60.5em) {
  .PfsArticleCta {
    display: block;
  }
  .browseButton {
    position: initial;
  }
  .Cards {
    margin-top: 3em;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media screen and (max-width: 38.2em) {
  .sideCards {
    display: block;
  }
}
