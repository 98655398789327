.PFSWaveContainer {
}

#ContentWrapper {
  color: white;
  padding: 1em 0em;
}

.WaveContainer {
  position: relative;
}
