.Modal div {
  border-radius: 1em;
}
#FormContainer {
  margin: 2em 3em;
  text-align: center;
  color: var(--color-pfs-gray);
}
#FormContainer input {
  font-size: 1.1em;
  width: 100%;
  border-radius: 1em;
  padding: 0.5em;
  border: 1px solid var(--color-pfs-light-gray);
  margin-bottom: 1em;
  box-sizing: border-box;
}
#FormContainer h4 {
  font-size: 2em;
  line-height: 1.25em;
  font-weight: bold;
}
#FormContainer p {
  font-size: 1.1em;
  margin-bottom: 2em;
}
.InputsSection {
  display: flex;
  gap: 0.8em;
}
.submitButton {
  width: 10em;
  height: 3em;
  border: none;
  background-color: var(--color-pfs-blue);
  color: white;
  border-radius: 1em;
  cursor: pointer;
}
.submitButton:disabled {
  background-color: var(--color-pfs-light-gray);
  cursor: default;
}
@media screen and (max-width: 33em) {
  #FormContainer {
    margin: 2em 0em;
  }
}
