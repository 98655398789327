.SpotlightArticleCard {
  width: 27em;
  height: 25em;
  border-radius: 1em;
  position: relative;
  text-align: start;
  margin: 1em 0em;
  background-color: black;
}
.SpotlightImage {
  width: 100%;
  height: 25em;
  border-radius: 1em;
  object-fit: cover;
  opacity: 0.55;
}
.text {
  width: 85%;
  position: absolute;
  color: white;
  top: 78%;
  left: 48%;
  transform: translate(-50%, -50%);
}
.ArticleTitle {
  font-size: 1.5em;
  font-weight: bold;
}
.icon {
  margin-right: 0.75em;
}
@media screen and (max-width: 60.5em) {
  .SpotlightArticleCard {
    width: 100%;
  }
}
