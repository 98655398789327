.ArticleBanner {
  border-radius: 1em;
  position: relative;
  text-align: center;
  background-color: black;
}
.BannerImage {
  width: 100%;
  height: 50vh;
  border-radius: 1em;
  object-fit: cover;
  opacity: 0.5;
}
.textcentered {
  width: 80%;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ArticleTitle {
  font-size: 1.7em;
  font-weight: bold;
}
.icon {
  margin-right: 0.75em;
}
