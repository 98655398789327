.Accordion {
  border-radius: 1.125em;
  display: flex;
  margin-top: 2em;
  justify-content: space-between;
  align-items: center;
  height: 5em;
  cursor: pointer;
}
.Accordion div {
  margin: 0;
  display: flex;
  align-items: center;
}
.Accordion span {
  margin-left: 1em;
}

.Closed {
  box-shadow: 0.375em 0.375em 1.5em #e3e3e3;
}
.Closed .icon {
  transform: rotate(-180deg);
}
.icon {
  margin-right: 1em;
  font-size: 2em;
  border-radius: 50%;
  transition: transform 0.5s;
}
.Loader {
  border: 0.5em solid rgb(229, 229, 229);
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 3em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
