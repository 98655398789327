.PFSWhyChoosePFS {
    margin-top: 5em;
}
.Reason {
    display: inline-block;
    width: 33.33%;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
}

.IconContainer {
    color: white;
    background-color: var(--color-pfs-blue);
    border-radius: 100%;
    width: 8.25em;
    height: 8.25em;
    margin: 0 auto;
    position: relative;
}

.IconContainer svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.TitleContainer {
    font-size: 1.56em;
    margin-top: 1em;
    margin-bottom: 0em;
    font-weight: 900;
}

.DescriptionContainer {
    font-size: 1.375em;
    padding: 0em 0.5em;
}

@media screen and (max-width: 64em) {
    .Reason {
        width: 50%;
    }
}

@media screen and (max-width: 35.5em) {
    .Reason {
        width: 100%;
    }
}