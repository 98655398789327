.PfsArticleList {
}
.PfsArticles {
  padding: 2em;
}
.HeadingWithLine {
  font-size: 2em;
  color: var(--color-pfs-text-gray);
  display: flex;
}
.MarginRight {
  margin-right: 0.25em;
}
.HeadingWithLine:after {
  content: '';
  flex: 1 1;
  border-top: 0.05em solid var(--color-pfs-light-gray);
  margin: auto;
  margin-left: 0.5em;
}
.loadMoreButton {
  border: none;
  color: white;
  background-color: var(--color-pfs-blue);
  border-radius: 1em;
  padding: 0.3em;
  width: 15em;
  margin: 2em;
  cursor: pointer;
}
.loadMoreButton:hover {
  background-color: var(--color-pfs-blue-hover);
}
@media screen and (max-width: 35em) {
  .HeadingWithLine:after {
    content: '';
    border-top: none;
  }
  .HeadingWithLine {
    display: block;
    text-align: center;
  }
}
.Loader {
  border: 0.5em solid var(--color-pfs-light-gray);
  border-top: 0.5em solid var(--color-pfs-blue);
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 3em;
  margin: 15em auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
