.Content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    text-align: center;
}

.Content h2 {
    font-size: 3em;
}

.Content p {
    font-size: 1.25em;
}

.IconWrapper {
    color: var(--color-pfs-blue);
}