.ScrollUp {
}
.icon {
  position: fixed;
  bottom: 2.5em;
  right: 1em;
  padding: 0.4em;
  height: 0.75em;
  width: 0.75em;
  background-color: var(--color-pfs-blue);
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
}
@media screen and (max-width: 75em) {
  .icon {
    height: 0.5em;
    width: 0.5em;
  }
}
