.PFSContact {
    padding-bottom: 5em;
}

#TitleContainer {
    font-size: 4em;
    color: var(--color-pfs-gray);
    font-weight: 900;
}

#ContactInfoContainer {
    display: inline-block;
    width: 40%;
    margin-bottom: 5em;
    padding-left: 6.18%;
}

.InfoContainer {
    border: 1px solid var(--color-pfs-blue);
    border-radius: 1em;
    margin-bottom: 1em;
    padding: 0.25em 1em;
    padding-top: 1.25em;
    font-size: 1.618em;

    z-index: 1;
    position: relative;
    background-color: white;
}

.InfoContainer p span {
    font-weight: 600;
}

.InfoContainer p svg {
    color: var(--color-pfs-blue);
    border-radius: 100%;
    padding-right: 1em;
}

#HoursContainer {
    padding-left: 2em;
    padding-bottom: 1.618em;
}

#HoursContainer p {
    margin: 0.25em 0;
    font-size: 0.85em;
}

#HoursContainer .HourTitle {
    color: var(--color-pfs-blue);
    font-weight: 600;
    font-size: 1em;
    margin-top: 1em;
}

#ContactFormContainer {
    display: inline-block;
    width: 40%;
    margin-left: 6.18%;
    vertical-align: top;
}

@media screen and (max-width: 48em) {
    #ContactContainer {
        width: 100%;
    }

    #ContactInfoContainer,
    #ContactFormContainer {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0em 1em;
        box-sizing: border-box;
    }
}