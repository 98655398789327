.PfsCareers {
  margin-top: 2em;
  margin-bottom: 15em;
}

#HeaderText {
  font-weight: 400;
  font-size: 3em;
  color: var(--color-pfs-gray);
}

#BoldHeader {
  font-weight: 900;
  font-size: 1.2em;
}

#BannerText {
  font-size: 1.5em;
  margin-top: 1.5em;
}

#CareersInfoContainer {
  display: inline-block;
  width: 40%;
  padding-left: 6.18%;
}

#CareersFormContainer {
  display: inline-block;
  width: 40%;
  margin-left: 6.18%;
  vertical-align: top;
}

@media screen and (max-width: 48em) {
  #CareersContainer {
    width: 100%;
  }

  #CareersInfoContainer,
  #CareersFormContainer {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0em 1em;
    box-sizing: border-box;
  }
}
