.PFSCareersForm {
  position: relative;
}

#BackgroundLogoContainer {
  position: absolute;
  height: 100%;
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 0;
}

#BackgroundLogoContainer svg {
  height: 100%;
  width: auto;
  transform: translate(-20%, -2%);

  fill: #000000;
  opacity: 10%;
}

#FormContainer {
  position: relative;
  z-index: 1;
}

#FormContainer input,
#FormContainer label,
#FormContainer textarea {
  display: block;
  font-size: 1.618em;
  width: 100%;
  border-radius: 1em;
  padding: 0.618em;
  border: 1px solid var(--color-pfs-light-gray);
  color: var(--color-pfs--gray);
  box-sizing: border-box;
  margin-bottom: 1em;
}
::placeholder {
  color: var(--color-pfs-light-gray);
}
#FormContainer textarea {
  font-size: 1.5em;
  height: 5.5em;
}

#FormContainer .NameInputs {
  width: 50%;
  display: inline-block;
}

#FormContainer .NameInputs:first-child input {
  width: 95%;
}

#FormContainer input[type='submit'] {
  background-color: var(--color-pfs-blue);
  color: white;
  margin-left: 0.5em;
  cursor: pointer;
  font-size: 1.3em;
  height: fit-content;
}

#FormContainer label {
  border-color: var(--color-pfs-blue);
  color: var(--color-pfs-blue);
  margin-right: 0.5em;
  font-size: 1.3em;
  height: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
#FormContainer svg {
  background-color: var(--color-pfs-blue);
  color: white;
  margin-right: 0.5em;
  padding: 0.35em;
  border-radius: 50%;
}
#FormContainer input[type='submit']:hover {
  background-color: var(--color-pfs-blue-hover);
}

#FormContainer input:disabled,
#FormContainer textarea:disabled {
  cursor: not-allowed;
}

.ErrorNotification,
.SuccessNotification {
  padding: 1em;
  margin: 0.25em 0em;
  color: white;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.25em;
}

.SuccessNotification {
  background-color: green;
}

.ErrorNotification {
  background-color: red;
}

.buttonsContainer {
  display: flex;
}

@media screen and (max-width: 48em) {
  #FormContainer .NameInputs {
    width: 100%;
    display: block;
  }
  .buttonsContainer {
    display: block;
  }
  #FormContainer label {
    margin-right: 0em;
  }
  #FormContainer input[type='submit'] {
    margin-left: 0em;
  }
  #FormContainer .NameInputs:first-child input {
    width: 100%;
  }

  #BackgroundLogoContainer {
    overflow: hidden;
  }
}
