.PFSStaffMember {
  height: 22em;
  width: 18.75em;
  padding: 1em;
  margin-top: 2em;
  display: inline-block;
  vertical-align: top;
}
.PFSStaffMember p {
  color: var(--color-pfs-gray);
  font-weight: 500;
  font-size: 1.25em;
  margin: 0.5em 0 0 0;
}
.ImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 11.2em;
  height: 11.2em;
  border: 0.5em solid var(--color-pfs-image-border-blue);
}
.PFSStaffMemberImage {
  height: 9em;
  width: 9em;
  border-radius: 50%;
  object-fit: cover;
}
.PersonTitle {
  opacity: 0.5;
}
