.PFSFooterNav {
    padding: 1em 1em 6.18em;
}

.NavSection {
    display: inline-block;
    width: 25%;
    vertical-align: top;
}

.NavSectionHeader {
    color: var(--color-pfs-blue);
    font-weight: 800;
    margin-bottom: 1em;
}

.NavSectionList {
    list-style: none;
    padding: 0;
}

.NavSectionList li {
    padding-top: 1em;
}

.NavSectionList a {
    color: black;
    text-decoration: none;
}
.Table{
    border-collapse: separate;
    border-spacing: 0 0.5em;
}
#NavIconSection table {
    border-spacing: 0.5em;
}

.NavToolsText {
    padding-left: 1em;
}

.NavToolsText a {
    color: black;
    text-decoration: none;
}

#NavIconSection a,
.NavToolsIcon a {
    color: white;
}

#NavIconSection td,
.NavToolsIcon {
    background-color: var(--color-pfs-blue);
    height: 3.825em;
    width: 3.825em;
    margin-right: 0.618em;
    border-radius: 50%;
    text-align: center;
}

@media screen and (max-width: 40em) {
    .NavSection:nth-child(2),
    .NavSection:nth-child(3) {
        width: 50%;
    }

    .NavSection {
        width: 100%;
        margin-bottom: 2.5em;
    }
}